import React from 'react';

import { Image } from '@common/components/Image';

import * as S from './Strategy.style';
import * as T from './Strategy.types';

export const Strategy: React.FC<T.StrategyCollection> = ({ image, items, line1, line2 }) => {
  return (
    <S.Strategy id="strategy">
      <S.Text>
        <h2>
          {line1}
          <strong>{line2}</strong>
        </h2>
        <ul>
          {items.map((item) => {
            return (
              <li key={item.title}>
                <Image src={item.image.url} alt={item.title} width="75px" height="75px" />
                <p>
                  <strong>{item.title}</strong>
                  {item.subtitle}
                </p>
              </li>
            );
          })}
        </ul>
      </S.Text>
      <Image src={image.url} alt={`${line1} ${line2}`} width="500px" height="500px" />
    </S.Strategy>
  );
};
