import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex, Text } from '@olist/united';

export const Hero = styled.section`
  position: relative;
  > img {
    height: 90%;
    left: 0;
    object-fit: cover;
    object-position: center -150px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
`;

export const Container = styled(Flex)`
  align-items: inherit;
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.medium')};
  justify-content: center;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > div {
    text-align: center;
    width: 400px;
    &:not(:first-child) {
      border: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
      box-shadow: ${themeGet('olist.shadow.low')};
    }
    a {
      margin-top: 20px;
    }
  }
`;

export const H1 = styled(Text.H1)`
  color: ${themeGet('olist.color.neutral.dark')};
  display: block;
  margin: calc(${themeGet('olist.spacing.stack.large')}) auto
    calc(${themeGet('olist.spacing.stack.huge')} * 5.5) auto;
  min-width: 100%;
  text-align: center;
  h1 {
    margin: 0 auto;
    max-width: 700px;
  }
  h3 {
    font-size: ${themeGet('olist.fontSize.large')};
    margin: 0 auto;
    max-width: 700px;
  }
`;
