import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Benefits = styled.section`
  background: ${themeGet('olist.color.primary.softer')};
  padding: 100px 0;
  position: relative;
`;

export const Container = styled(Flex)`
  align-items: inherit;
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.medium')};
  justify-content: center;
  margin: 0 auto;
  max-width: 800px;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  > div {
    border: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
    box-shadow: ${themeGet('olist.shadow.low')};
    width: calc(50% - ${themeGet('olist.spacing.stack.medium')});
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            width: 100%;
          }
        `
      );
    }}
    a {
      margin-top: 20px;
    }
  }
`;
