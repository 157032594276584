import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Strategy = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.huge')};
  justify-content: center;
  padding: 100px ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > div {
    width: 35%;
    ${({ theme }) => {
      return (
        theme &&
        css`
          @media (max-width: ${theme.breakpoints.small}) {
            width: 100%;
          }
        `
      );
    }}
    h2 {
      font-size: calc(${themeGet('olist.fontSize.2xhuge')} * 1.5);
      line-height: calc(${themeGet('olist.fontSize.2xhuge')} * 1.5);
      margin-bottom: ${themeGet('olist.spacing.stack.large')};
      strong {
        display: block;
        font-weight: ${themeGet('olist.fontWeight.bold')};
        color: ${themeGet('olist.color.primary.soft')};
      }
    }
    ul {
      li {
        align-items: center;
        display: flex;
        margin-bottom: ${themeGet('olist.spacing.stack.large')};
        p {
          margin-left: ${themeGet('olist.spacing.stack.medium')};
          strong {
            color: ${themeGet('olist.color.primary.soft')};
            display: inline-block;
            font-size: ${themeGet('olist.fontSize.large')};
            font-weight: ${themeGet('olist.fontWeight.bold')};
            margin-right: ${themeGet('olist.spacing.stack.1xsmall')};
          }
        }
      }
    }
  }
`;

export const Text = styled.div``;
