import React from 'react';

import { Text } from '@olist/united';

import * as S from './Contact.style';
import { FormActiveCampaign } from './Form';

export const Contact: React.FC = () => {
  return (
    <S.Contact id="contact">
      <h2>
        <Text.H2>Torne-se um parceiro agora</Text.H2>
      </h2>
      <S.FormWrapper>
        <FormActiveCampaign />
      </S.FormWrapper>
    </S.Contact>
  );
};
