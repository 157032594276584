import React, { useEffect } from 'react';

export const FormActiveCampaign: React.FC = () => {
  const EMBED_SCRIPT = {
    SRC: 'https://vnda.activehosted.com/f/embed.php?id=144',
    ID: '_form_144',
  };

  useEffect(() => {
    const script = document.createElement('script');

    script.src = EMBED_SCRIPT.SRC;
    script.async = true;

    document.body.appendChild(script);
  });
  return <div className={EMBED_SCRIPT.ID} />;
};
