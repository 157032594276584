import React from 'react';

import { Card, Text } from '@olist/united';

import { Image } from '@common/components/Image';

import * as S from './Benefits.style';
import * as T from './Benefits.types';

export const Benefits: React.FC<T.BenefitsCollection> = ({ items }) => {
  return (
    <S.Benefits id="benefits">
      <S.Container>
        {items.map((item) => {
          return (
            <Card key={item.title}>
              <Image src={item.image.url} alt={item.title} width="75px" height="75px" />
              <Text.H3>{item.title}</Text.H3>
              <Text>{item.subtitle}</Text>
            </Card>
          );
        })}
      </S.Container>
    </S.Benefits>
  );
};
