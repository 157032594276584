import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Contact = styled(Flex)`
  align-items: flex-start;
  gap: ${themeGet('olist.spacing.stack.large')};
  justify-content: center;
  margin: 100px auto;
  max-width: 1328px;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
          flex-direction: column;
        }
      `
    );
  }}
  h2 {
    max-width: 350px;
  }
`;

export const FormWrapper = styled.div`
  width: 350px;
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.small}) {
          width: 100%;
        }
      `
    );
  }}
  form._form_144 {
    margin: 0 !important;
    padding: 0 !important;
    width: 100%;
    input,
    select,
    textarea {
      background: transparent;
      border: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
      border-radius: 5px !important;
      box-shadow: ${themeGet('olist.shadow.low')};
      font: italic 14px/19px !important;
      padding: calc(${themeGet('olist.spacing.stack.medium')} / 2) !important;
      width: 100%;
    }
    .msgSuccess {
      display: block;
      margin-bottom: 0;
      margin-top: 20px;
    }
    > div > div {
      position: relative;
      margin-bottom: 20px !important;
      width: 100%;
    }
    ._button-wrapper ._submit {
      background: ${themeGet('olist.color.primary.base')} !important;
    }
    label {
      background: ${themeGet('olist.color.neutral.softest')};
      color: ${themeGet('olist.color.neutral.base')};
      display: flex;
      font-size: 14px !important;
      font-style: italic !important;
      font-weight: 400;
      left: calc(${themeGet('olist.spacing.stack.medium')} / 2);
      padding: 0 5px;
      position: absolute;
      transform: translateY(-50%);
      transition: 0.3s;
      top: 0px;
      z-index: 9;
      .req {
        color: ${themeGet('olist.color.primary.base')};
      }
    }
    textarea {
      + label {
        top: 25px;
      }
    }
    ._clear,
    ._form-branding {
      display: none !important;
    }
    ._form_element {
      position: relative;
    }
  }
`;
