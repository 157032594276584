import React from 'react';

import { Card, Text } from '@olist/united';

import * as S from './Testimonials.style';
import * as T from './Testimonials.types';

export const Testimonials: React.FC<T.TestimonialsCollection> = ({ items, title }) => {
  return (
    <S.Testimonials>
      <S.Container>
        <h2>
          <Text.H2>{title}</Text.H2>
        </h2>
        {items.map((item) => {
          return (
            <Card key={item.name}>
              <Card.Content>
                <Text>{item.text}</Text>
              </Card.Content>
              <Card.Footer>
                <Text.Small>
                  <strong>{item.name}</strong>
                </Text.Small>
                <Text.Small>{item.job}</Text.Small>
              </Card.Footer>
            </Card>
          );
        })}
      </S.Container>
    </S.Testimonials>
  );
};
