import React from 'react';

import { Card, Text } from '@olist/united';

import { Image } from '@common/components/Image';

import * as S from './Hero.style';
import * as T from './Hero.types';

export const Hero: React.FC<T.HeroCollection> = ({ categories, image, subtitle, title }) => {
  return (
    <S.Hero id="program">
      <Image src={image.url} alt={title} width="1920px" height="500px" />
      <S.Container>
        <S.H1>
          <h1>{title}</h1>
          <h3>{subtitle}</h3>
        </S.H1>
        {categories.map((item) => {
          return (
            <Card key={item.title}>
              <Image src={item.image.url} alt={item.title} width="75px" height="75px" />
              <Text.H3>{item.title}</Text.H3>
              <Text>{item.subtitle}</Text>
            </Card>
          );
        })}
      </S.Container>
    </S.Hero>
  );
};
