import { Benefits } from 'modules/home/components/Benefits';
import { BenefitsCollection } from 'modules/home/components/Benefits/Benefits.types';
import { Contact } from 'modules/home/components/Contact';
import { Footer } from 'modules/home/components/Footer';
import { Header } from 'modules/home/components/Header';
import { Hero } from 'modules/home/components/Hero';
import { HeroCollection } from 'modules/home/components/Hero/Hero.types';
import { NewPartners } from 'modules/home/components/NewPartners';
import { PartnersCollection } from 'modules/home/components/NewPartners/NewPartners.types';
import { Strategy } from 'modules/home/components/Strategy';
import { StrategyCollection } from 'modules/home/components/Strategy/Strategy.types';
import { Testimonials } from 'modules/home/components/Testimonials';
import { TestimonialsCollection } from 'modules/home/components/Testimonials/Testimonials.types';

import contentfulData from '../services/contentful';
import { queryLandingPage } from '../services/contentful/querys';

type HomeProps = {
  hero: HeroCollection;
  partners: PartnersCollection[];
  benefits: BenefitsCollection;
  testimonials: TestimonialsCollection;
  strategy: StrategyCollection;
};

const Home = ({ benefits, hero, partners, strategy, testimonials }: HomeProps) => {
  return (
    <>
      <Header />
      <main>
        <Hero
          image={hero.image}
          title={hero.title}
          subtitle={hero.subtitle}
          categories={hero.categories}
        />
        <NewPartners items={partners[0].items} hasLink />
        <Benefits items={benefits.items} />
        <Strategy
          line1={strategy.line1}
          line2={strategy.line2}
          image={strategy.image}
          items={strategy.items}
        />
        {testimonials.items.length > 0 && (
          <Testimonials items={testimonials.items} title={testimonials.title} />
        )}
        <Contact />
      </main>
      <Footer />
    </>
  );
};

export default Home;

export async function getServerSideProps() {
  const data = await contentfulData(queryLandingPage());

  return {
    props: data,
  };
}
