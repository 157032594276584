import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';

import { Flex } from '@olist/united';

export const Testimonials = styled.section`
  background: ${themeGet('olist.color.primary.softer')};
  padding: 100px 0;
  position: relative;
`;

export const Container = styled(Flex)`
  align-items: center;
  flex-wrap: wrap;
  gap: ${themeGet('olist.spacing.stack.medium')};
  justify-content: center;
  margin: 0 auto;
  max-width: 1328px;
  padding: 0 ${themeGet('olist.spacing.stack.medium')};
  ${({ theme }) => {
    return (
      theme &&
      css`
        @media (max-width: ${theme.breakpoints.large}) {
          margin: auto ${themeGet('olist.spacing.stack.large')};
        }

        @media (max-width: ${theme.breakpoints.small}) {
          margin: auto ${themeGet('olist.spacing.stack.1xsmall')};
        }
      `
    );
  }}
  > h2 {
    width: 30%;
  }
  > div {
    border: 1px solid ${themeGet('olist.color.neutral.softer')} !important;
    box-shadow: ${themeGet('olist.shadow.low')};
    padding: 0;
    width: 30%;
    a {
      margin-top: 20px;
    }
    strong {
      color: ${themeGet('olist.color.primary.base')};
      font-weight: ${themeGet('olist.fontWeight.bold')};
    }
  }
`;
